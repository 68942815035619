class Header extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    const dirStart = location.href.indexOf("/", 8) + 1;
    this.innerHTML = `
    <nav class="navbar navbar-expand-lg navbar-dark fixed-top py-0 flex-column font-heading">
      <div id="sub-nav" class="bg-darker text-white w-100 px-2 px-sm-3 lh-1 d-flex align-items-center">
        <div class="d-flex d-lg-none align-items-center w-100">
          <span class="opacity-75 small text-nowrap">You a care recipient/family member?</span>
          <a href="/feedback" class="btn btn-sm btn-light rounded-pill fw-bold py-0 ms-auto ms-lg-3 text-darker opacity-90 opacity-75-hover me-md-2">Yes</a>
        </div>
        <div class="d-none d-lg-flex align-items-center w-100">
          <span class="opacity-75 small">Are you a care recipient or family member<span class="d-none d-md-inline"> looking to <strong>submit feedback</strong> or <strong>view replies</strong></span>?</span>
          <a href="/feedback" class="btn btn-sm btn-light rounded-pill fw-bold py-0 ms-auto ms-lg-3 text-darker opacity-90 opacity-75-hover" title="Instructions for consumers and their families">Instructions</a>
        </div>
        <div class="ms-auto d-none d-md-flex">
          <a href="https://apps.apple.com/us/app/tell-touch/id1465370268?ls=1" class="me-2 opacity-75-hover" title="Get Tell Touch from the App Store"><img src="/assets/images/theme/appstore-white.svg" width="85" height="25" alt="App Store button" loading="lazy"></a>
          <a href="https://play.google.com/store/apps/details?hl=en_AU&amp;id=au.com.slay.telltouch" class="opacity-75-hover" title="Get Tell Touch from the Google Play Store"><img src="/assets/images/theme/googleplay-white.svg" width="85" height="25" alt="Google Play Store button" loading="lazy"></a>
        </div>
      </div>
      <div class="container-fluid" id="top-nav">
        <a class="navbar-brand py-0 ps-2" href="/" title="Tell Touch Home"><img src="/assets/images/brand/logo.svg" height="80" width="228" alt="Tell Touch logo" class="d-none d-sm-block" /><img src="/assets/images/brand/logo.svg" height="80" width="200" alt="Tell Touch logo" class="d-sm-none" /></a>
        <button class="navbar-toggler align-items-center d-flex d-lg-none rounded-pill p-2 px-sm-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTop" aria-controls="navbarTop" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
          <span class="d-none d-sm-inline ms-2">Menu</span>
        </button>
    
        <div class="collapse navbar-collapse" id="navbarTop">
          <ul class="navbar-nav ms-auto mb-3 mb-lg-0 align-items-lg-center">
            <li class="nav-item d-lg-none d-xl-block">
              <a class="nav-link me-1 px-3 py-2 fs-5 rounded-pill ${location.href.substring(dirStart) == "" ? "bg-brand-darker" : ""}" href="/">Home</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link me-1 px-3 py-2 fs-5 rounded-pill ${location.href.substring(dirStart).indexOf("products") == 0 ? "bg-brand-darker" : ""}" href="/products" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Products</a>
              <ul class="dropdown-menu dropdown-menu-dark dropdown-menu-md-end" aria-labelledby="navbarDropdown">
                <li>
                  <a class="dropdown-item py-2 d-flex align-items-center w-320px" href="/products/feedback">
                    <img class="me-3" width="60" height="60" src="/assets/images/index/products-instant-feedback.svg" alt="Instant Feedback" />
                    <span class="text-wrap lh-sm">
                      <strong class="d-block font-display">Instant Feedback&trade;</strong>
                      <small>Capture, reply to and resolve feedback. View reports and gain actionable insights.</small> 
                    </span>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item py-2 d-flex align-items-center w-320px" href="/products/staff">
                    <img class="me-3" width="60" height="60" src="/assets/images/index/products-staffpulse.svg" alt="Staff Feedback" />
                    <span class="text-wrap lh-sm">
                      <strong class="d-block font-display">StaffPulse&trade;</strong>
                      <small>Gather staff feedback similar to Instant Feedback.</small> 
                    </span>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item py-2 d-flex align-items-center w-320px" href="/products/surveys">
                    <img class="me-3" width="60" height="60" src="/assets/images/index/products-surveys.svg" alt="Surveys" />
                    <span class="text-wrap lh-sm">
                      <strong class="d-block font-display">SurveysSmart&trade;</strong>
                      <small>Collate, track and report on industry mandated surveys, or custom surveys.</small> 
                    </span>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item py-2 d-flex align-items-center w-320px" href="/products/audits">
                    <img class="me-3" width="60" height="60" src="/assets/images/index/products-audits.svg" alt="Audits" />
                    <span class="text-wrap lh-sm">
                      <strong class="d-block font-display">AuditEase&trade;</strong>
                      <small>Craft audits and gain insight with advanced analytics.</small> 
                    </span>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item py-2 d-flex align-items-center w-320px" href="/products/nps">
                    <img class="me-3" width="60" height="60" src="/assets/images/index/products-nps-dark.svg" alt="Net Promoter Score®" />
                    <span class="text-wrap lh-sm">
                      <strong class="d-block font-display">Net Promoter Score®</strong>
                      <small>NPS®-style surveys to benchmark your consumer engagement activities.</small>
                    </span>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item py-2 d-flex align-items-center w-320px" href="/products/wellbeing">
                    <img class="me-3" width="60" height="60" src="/assets/images/index/products-wellbeingwatch.svg" alt="WellbeingWatch" />
                    <span class="text-wrap lh-sm">
                      <strong class="d-block font-display">WellbeingWatch&trade;</strong>
                      <small>Collect and analyse wellbeing observations from non-clinical carers.</small>
                    </span>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item py-2 d-flex align-items-center w-320px" href="/products/messaging">
                    <img class="me-3" width="60" height="60" src="/assets/images/index/products-messaging.svg" alt="Messaging" />
                    <span class="text-wrap lh-sm">
                      <strong class="d-block font-display">MessageOut&trade;</strong>
                      <small>Send email and text messages to care recipients and their representatives.</small> 
                    </span>
                  </a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link me-1 px-3 py-2 fs-5 rounded-pill ${location.href.substring(dirStart).indexOf("faqs") == 0 ? "bg-brand-darker rounded" : ""}" href="/faqs">FAQs</a>
            </li>
            <li class="nav-item">
              <a class="nav-link me-1 px-3 py-2 fs-5 rounded-pill ${location.href.substring(dirStart).indexOf("pricing") == 0 ? "bg-brand-darker rounded" : ""}" href="/pricing">Pricing</a>
            </li>
            <li class="nav-item">
              <a class="nav-link me-1 px-3 py-2 fs-5 d-flex align-items-center text-nowrap rounded-pill" href="tel:1300835586">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-telephone-fill opacity-75 me-1" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                </svg>
                1300 83 55 86
              </a>
            </li>             
            <li class="nav-item d-lg-none d-xl-inline">
              <a class="nav-link dropdown-toggle px-3 py-2 fs-5 d-flex align-items-center rounded-pill ${location.href.substring(dirStart).indexOf("languages") == 0 ? "bg-brand-darker rounded" : ""}" href="/languages" role="button">
                <span class="d-none d-lg-inline">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-globe2 opacity-75 me-1" viewBox="0 0 16 16">
                    <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855-.143.268-.276.56-.395.872.705.157 1.472.257 2.282.287V1.077zM4.249 3.539c.142-.384.304-.744.481-1.078a6.7 6.7 0 0 1 .597-.933A7.01 7.01 0 0 0 3.051 3.05c.362.184.763.349 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9.124 9.124 0 0 1-1.565-.667A6.964 6.964 0 0 0 1.018 7.5h2.49zm1.4-2.741a12.344 12.344 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332zM8.5 5.09V7.5h2.99a12.342 12.342 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.612 13.612 0 0 1 7.5 10.91V8.5H4.51zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741H8.5zm-3.282 3.696c.12.312.252.604.395.872.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a6.696 6.696 0 0 1-.598-.933 8.853 8.853 0 0 1-.481-1.079 8.38 8.38 0 0 0-1.198.49 7.01 7.01 0 0 0 2.276 1.522zm-1.383-2.964A13.36 13.36 0 0 1 3.508 8.5h-2.49a6.963 6.963 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667zm6.728 2.964a7.009 7.009 0 0 0 2.275-1.521 8.376 8.376 0 0 0-1.197-.49 8.853 8.853 0 0 1-.481 1.078 6.688 6.688 0 0 1-.597.933zM8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855.143-.268.276-.56.395-.872A12.63 12.63 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.963 6.963 0 0 0 14.982 8.5h-2.49a13.36 13.36 0 0 1-.437 3.008zM14.982 7.5a6.963 6.963 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008h2.49zM11.27 2.461c.177.334.339.694.482 1.078a8.368 8.368 0 0 0 1.196-.49 7.01 7.01 0 0 0-2.275-1.52c.218.283.418.597.597.932zm-.488 1.343a7.765 7.765 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z"/>
                  </svg>
                  EN
                </span>
                <span class="d-lg-none">Languages</span>
              </a>
            </li>
            <li class="nav-item d-lg-inline ms-lg-1 mt-2 mt-lg-0">
              <a href="/demo" class="btn btn-lg btn-white btn-icon link-brand fw-bold rounded-pill d-inline-flex align-items-center ps-0 py-0 text-nowrap border-0 me-3">
                <span class="s48x48 bg-success text-white d-inline-flex h-100 justify-content-center align-items-center me-2 rounded-circle">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-calendar-event-fill flex-shrink-0" viewBox="0 0 16 16">
                    <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-3.5-7h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z" />
                  </svg>
                </span>
                <span class="py-2">Book a demo</span>
              </a>
            </li>
            <li class="nav-item d-lg-none bg-brand-darker mt-2 mb-n3 mx-n3 py-2 px-3">
              <a href="https://apps.apple.com/us/app/tell-touch/id1465370268?ls=1" class="me-1 opacity-75-hover"><img src="/assets/images/theme/appstore-white.svg" width="102" height="30"></a>
              <a href="https://play.google.com/store/apps/details?hl=en_AU&amp;id=au.com.slay.telltouch" class="opacity-75-hover"><img src="/assets/images/theme/googleplay-white.svg" width="102" height="30"></a>
            </li>
          </ul>
          <hr class="d-lg-none m-0 bg-dark" />
        </div>
      </div>
    </nav>
    <div id="nav-spacer"></div>
    <div id="nav-shadow"></div>
    `;
  }
}

customElements.define("header-component", Header);
